
import Vue from 'vue'
Vue.directive('dialogDrag', {
    bind: (el) => {
      // 获取弹框标题区域DOM节点
      const headerDOM = el.querySelector('.el-dialog__header');
      // 修改鼠标图标样式
      headerDOM.style.cursor = "move";
      // 禁止拖拽时选中标题中文本内容
      headerDOM.style.userSelect = "none";
      // 获取弹框区域的DOM节点
      const dialogDOM = el.querySelector('.el-dialog');
      let isDown = false,         // 是否按下
          // 鼠标按下时坐标位置
          clientX = 0,
          clientY = 0,
          // 按下时弹框位置
          dialogLeft = 0,
          dialogTop = 0;
      // 定义函数判断当前是否在可见范围内
      function boundingRange(){
        const bounding = dialogDOM.getBoundingClientRect();
        return {
          top: bounding.top >= 0,       // 表示顶部在可见范围
          left: bounding.left >= 0,     // 表示左侧在可见范围
          right: bounding.left < window.innerWidth -10 - bounding.width,   // 表示右侧在指定范围
          bottom: bounding.top < window.innerHeight  -10 - bounding.height   // 表示底部在指定范围
        }
      }
      // 更新数据
      function update(e){
        // 获取当前鼠标按钮位置坐标
        clientX = e.clientX;
        clientY = e.clientY;
        // 获取弹框位置（默认情况弹框样式left和top可能不存在，当为NaN时初始化为0）
        dialogLeft = isNaN(parseFloat(dialogDOM.style.left))?0:parseFloat(dialogDOM.style.left);
        dialogTop = isNaN(parseFloat(dialogDOM.style.top))?0:parseFloat(dialogDOM.style.top);
      }
      // 监听鼠标按下事件
      headerDOM.onmousedown = e => {
        isDown = true;
        update(e);
      }
      // 监听鼠标移动事件
      headerDOM.onmousemove = e => {
        // 不按下的时候，执行移动操作
        if(isDown){
          // 获取DOM边界范围
          const range = boundingRange();
          // 获取当前移动到的位置坐标,与按下位置坐标进行计算，获取移动距离
          const distX = e.clientX - clientX;          // distX小于0为向左，大于0为向右
          const distY = e.clientY - clientY;          // distY小于0为向上，大于0为向下
          // 判断左侧或右侧是否可移动
          if((range.left && distX < 0) || (range.right && distX >= 0)) dialogDOM.style.left = (dialogLeft + distX) + "px";
          // 判断顶部或者底部是否可移动
          if((range.top && distY < 0) || (range.bottom && distY >= 0)) dialogDOM.style.top = (dialogTop + distY) + "px";
          // 更新起始位数据
          update(e);
        }
      }
      headerDOM.onmouseup = e => isDown = false;
      window.onmouseup = () => isDown = false;
    }
  })